


















































































































































































































































import Vue from "vue";
import { logisticServices } from "@/services/logistic.service";
import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import { ResponseWarehouse } from "@/models/interface/logistic.interface";
import { ResponsePagination } from "@/models/interface/common.interface";
import { getauthorities } from "@/helpers/cookie";
export default Vue.extend({
  data() {
    return {
      dataListWarehouse: {} as ResponseWarehouse,
      dataListWarehouseBranch: {} as any,
      trigerdisable: false as boolean,
      page: this.page || (1 as number),
      limit: this.limit || (10 as number),
      search: "" as string,
      sort: "" as string,
      direction: "" as string,
      authPrivilege: [] as string[],
      typemodal: "" as string,
      loadingTable: false as boolean,
      titlemodalOpen: "" as string,
      modalOpen: false as boolean,
      isFormSubmitted: false as boolean,
      form: this.$form.createForm(this, { name: "warehousebranch" }),
      labelCol: { span: 8 },
      wrapperCol: { span: 16 },
      formRules: {
        branch: {
          label: this.$t("lbl_branch_warehouse"),
          name: "branch",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "branch",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        name: {
          label: this.$t("lbl_name"),
          name: "name",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "name",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        address: {
          label: this.$t("lbl_address"),
          name: "address",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "address",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        code: {
          label: this.$t("lbl_code"),
          name: "code",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "code",
            {
              rules: [
                {
                  required: true,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
        type: {
          label: this.$t("lbl_type"),
          name: "type",
          placeholder: this.$t("lbl_type_here"),
          decorator: [
            "type",
            {
              rules: [
                {
                  required: false,
                  message: this.$t("lbl_validation_required_error"),
                },
              ],
            },
          ],
        },
      },
      rowDetail: {
        id: "",
        name: "",
        address: "",
        branchId: "",
        code: "",
        type: ""
      }
    };
  },

  mounted() {
    this.getListOfWarehouse("");
    this.getlistOfWarehouseBranch();
  },
  created() {
    this.setAuthorities();
  },
  methods: {
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "warehouse") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: 120,
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    onTableChange(pagination, filters, sorted: {columnKey: string, order?: string}): void {
      if (sorted.order) {
        this.direction = sorted.order === "ascend" ? "asc" : "desc";
        this.sort = `${sorted.columnKey}:${this.direction}`;
        this.getListOfWarehouse("");
      } else {
        this.resetFilter(true);
      }
    },
    onSorterChange(response): void {
      if (response.sort.order) {
        this.direction = response.sort.order === "ascend" ? "asc" : "desc";
        this.sort = `${response.sort.field}:${this.direction}`;
      } else {
        this.resetFilter(false);
      }
      this.getListOfWarehouse("");
    },
    async deleteRecord(row: {id: string}): Promise<void> {
      try {
        await logisticServices.deleteWarehouse(row.id);
        this.$message.success(this.$t("notif_delete_success").toString());
        this.resetFilter(false);
        this.getListOfWarehouse("");
      } catch (error) {
        console.error(error);
      }
    },
    fillForm(data: {branchId: string, id: string, name: string, address: string, code:string, type:string}) : void {
      let timeoutId;
      timeoutId = setTimeout(() => {
        this.form.setFieldsValue({
          branch: data.branchId || undefined,
          name: data.name,
          address: data.address,
          code: data.code,
          type: data.type,
        });
        clearTimeout(timeoutId);
      }, 200);
    },
    updateRecord(row: {branchWarehouse: {id: string}, id: string, name: string, address: string, code:string, type:string}): void {
      this.modalOpen = true;
      this.resetForm();
      this.trigerdisable = false;
      this.typemodal = "edit";
      this.titlemodalOpen = this.$t("lbl_edit_warehouse").toString();
      this.rowDetail = {
        id: row.id,
        branchId: row.branchWarehouse.id,
        name: row.name,
        address: row.address,
        code: row.code,
        type: row.type
      };
      this.fillForm(this.rowDetail);
      this.resetFilter(false);
    },
    resetFilter(getlist: boolean): void {
      this.direction = "";
      this.search = "";
      if (getlist) this.getListOfWarehouse("");
    },
    getlistOfWarehouseBranch(){
      let params = {
          limit: 1000,
          page: this.page - 1,
        } as RequestQueryParamsModel;
      logisticServices.listWarehouseBranch(params , "")
        .then((res: any) => {
            res.data.map((dataObject, index) => (dataObject.key = index));
            this.dataListWarehouseBranch = res;
        })
        .finally(() => (this.loadingTable = false));
    },
    async getListOfWarehouse(path: string): Promise<void> {
      try {
        let params: any;
        if (!path) {
          params = {
            limit: this.limit,
            page: this.page - 1,
            sorts:"createdDate:desc"
          } as RequestQueryParamsModel;
          if (this.search) params.search = this.search;
          if (this.direction) params.sorts = this.sort;
        }
        this.loadingTable = true;
        const res = await logisticServices.listWarehouse(params, path);
        res.data.forEach((el, i: number) => el.key = i);
        this.dataListWarehouse = res;
      } finally {
        this.loadingTable = false;
      }
    },
    reponseSearchInput(response): void {
      if (response) this.search = "name~*" + response + "*";
      else this.search = "";
      this.getListOfWarehouse("");
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limit = response.size;
      this.page = 1;
      this.getListOfWarehouse("");
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getListOfWarehouse("");
    },
    resetForm(): void {
      this.fillForm({branchId: "", id: "", address: "", name: "", code: "", type: ""});
    },
    createNew(): void {
      this.modalOpen = true;
      this.resetForm();
      this.typemodal = "create";
      this.titlemodalOpen = "Create New Warehouse";
      this.resetFilter(false);
    },
    async createWarehouse(data: {address: string, branch: string, name: string, code: string, type: string}): Promise<void> {
      try {
        this.isFormSubmitted = true;
        const req = {
          address: data.address,
          branchWarehouseId: data.branch,
          name: data.name,
          code: data.code,
          type: data.type
        };
        await logisticServices.createWarehouse(req);
        this.getListOfWarehouse("");
        this.modalOpen = false;
        this.$message.success(this.$t("notif_create_success").toString());
      } catch (error) {
        console.error(error);
      } finally {
        this.isFormSubmitted = false;
      }
    },
    async updateWarehouse(data: {address: string, branch: string, name: string, code: string, type: string}): Promise<void> {
      try {
        this.isFormSubmitted = true;
        const req = {
          address: data.address,
          branchWarehouseId: data.branch,
          name: data.name,
          code: data.code,
          type: data.type
        };
        await logisticServices.updateWarehouse(req, this.rowDetail.id);
        this.getListOfWarehouse("");
        this.modalOpen = false;
        this.$message.success(this.$t("notif_update_success").toString());
      } catch (error) {
        console.error(error);
      } finally {
        this.isFormSubmitted = false;
      }
    },
    submitForm(e: Event, typeModal: "edit" | "create"): void {
      e.preventDefault();
      this.form.validateFields((err, values) => {
        if (!err) {
          if (typeModal === "create") {
            this.createWarehouse(values);
          } else {
            this.updateWarehouse(values);
          }
        }
      });
    },
    handleCancel(): void {
      this.modalOpen = false;
    },
    filterOption (input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
      );
    },
  },
});
